import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { serializeData, headers, Params } from './../postformdata';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  dateRange: Date[];
  itemisVisible: boolean;
  listOfOption: any;
  twolistOfOption: any;
  selectedValue: any;
  newselectedbrand: any;
  checkOptionsOne: any;
  newkeyword: any;
  or_have: any;
  avoid_have: any;
  barcode: any;
  title: any;
  tmall_link: any;
  tmall_price: any;
  ischeckOptionsOne: boolean;
  selectedbrand: any;
  private _newkeyword: any;
  private _or_have: any;
  _avoid_have: any;
  _barcode: any;
  _title: any;
  _tmall_link: any;
  _tmall_price: any;
  testid: any;

  constructor(private http: HttpClient) { }
  host = localStorage.getItem('host')
  dateFormat = 'yyyy/MM/dd';
  keyword;
  brandname;
  daterangepickerModel;
  isVisible;
  itemvalue;
  newitemvalue;
  ngOnInit() {
  }
  onChange(result: Date[]): void {
    this.dateRange = result
  }


  csdowmload() {
    var data = {
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }

    headers['observe'] = 'response';
    headers['responseType'] = 'blob'
    this.http.post(this.host + '/report/csdownload/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var blob = new Blob([res['body']], { type: res['headers'].get("Content-Type") });
        var filename = res['headers'].get("Content-Disposition").split(";")[1].split("filename=")[1]
        saveAs(blob, filename)
      }
    }
    )
  }



  branddownload() {
    var data = {
      'keyword': this.keyword,
      'brand': this.brandname,
    }
    headers['observe'] = 'response';
    headers['responseType'] = 'blob'
    this.http.post(this.host + '/report/maijiabrandreport/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var blob = new Blob([res['body']], { type: res['headers'].get("Content-Type") });
        var filename = res['headers'].get("Content-Disposition").split(";")[1].split("filename=")[1]
        saveAs(blob, filename)
      }
    }
    )


  }

  getbrandcategories() {
    var data = {
      'brand': this.brandname,
    }
    this.http.post(this.host + '/report/getbrandcategories/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.listOfOption = res['data']
      }
    }
    )


  }
  getmjbrand() {
    var data = {
      'inputVal': this.selectedValue,
    }
    this.http.post(this.host + '/report/getmjbrand/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.twolistOfOption = res['data']
      }
    }
    )

  }
  getbranditems() {
    var data = {
      'brand': this.newselectedbrand,
    }
    this.http.post(this.host + '/report/getbranditems/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.checkOptionsOne = res['data']
      }
    }
    )


  }
  addreportbrand() {
    var data = {
      'category': this.selectedValue,
      'brand': this.selectedbrand,
    }
    this.http.post(this.host + '/report/addreportbrand/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {

      }
    }
    )
  }
  addreportitem() {
    var data = {
      "brand": this.itemvalue,
      "keyword": this.newkeyword,
      "or_have": this.or_have,
      "avoid_have": this.avoid_have,
      "barcode": this.barcode,
      "title": this.title,
      "tmall_link": this.tmall_link,
      "tmall_price": this.tmall_price,
    }
    this.http.post(this.host + '/report/addreportitem/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {

      }
    }
    )

  }
  editreportitem(){
    var data = {
      "id": this.testid,
      "keyword": this._newkeyword,
      "or_have": this._or_have,
      "avoid_have": this._avoid_have,
      "barcode": this._barcode,
      "title": this._title,
      "tmall_link": this._tmall_link,
      "tmall_price": this._tmall_price,
    }
    this.http.post(this.host + '/report/editreportitem/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {

      }
    }
    )
  }

  getreportitembyid(){
    var data = {
      'item_id': this.testid,
    }
    this.http.post(this.host + '/report/getreportitembyid/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
            this._newkeyword = res['data']['keyword']
            this._or_have = res['data']['or_have']
            this._avoid_have = res['data']['avoid_have']
            this._barcode = res['data']['barcode']
            this._title = res['data']['product_name']
            this._tmall_link = res['data']['tmall_link']
            this._tmall_price = res['data']['tmall_price']
      }
    }
    )
  }
  showModal(id) {
    this.testid = id
    this.ischeckOptionsOne = true
    this.newitemvalue = this.newselectedbrand
    this.getreportitembyid()
  }
  twohandleOk(): void {
    this.ischeckOptionsOne = false;
    this.editreportitem()
  }

  twohandleCancel(): void {
    this.ischeckOptionsOne = false;
  }

  showaddbrand() {
    this.isVisible = true
    this.getbrandcategories()
  }
  handleOk(): void {
    this.isVisible = false;
    this.addreportbrand()
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showadditem() {
    this.itemisVisible = true
    this.itemvalue = this.newselectedbrand
  }
  newhandleOk(): void {
    this.itemisVisible = false;
    this.addreportitem()
  }

  newhandleCancel(): void {
    this.itemisVisible = false;
  }

  itemdownload() {
    var keywordlist = []
    var data = {
      'keywords': keywordlist,
      'brand': this.newselectedbrand,
    }
    headers['observe'] = 'response';
    headers['responseType'] = 'blob'
    this.http.post(this.host + '/report/itempricereport/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var blob = new Blob([res['body']], { type: res['headers'].get("Content-Type") });
        var filename = res['headers'].get("Content-Disposition").split(";")[1].split("filename=")[1]
        saveAs(blob, filename)
      }
    }
    )

  }


}
