import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LocalStorage } from './extras/localstorage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectizeModule } from 'ng-selectize';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CountUpModule } from 'ngx-countup';
//Layout Modules
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { Page403Component } from './extras/403/403.component';
import { Page500Component } from './extras/500/500.component';
import { PromotionSalesComponent } from './master/promotion-sales/promotion-sales.component';
//Directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular-highcharts';
// Routing Module
import { AppRoutes } from './app.routing';

// App Component
import { AppComponent } from './app.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular'
import zh from '@angular/common/locales/zh';
import { ReportComponent } from './report/report.component';
import { SuperdashboardComponent } from './superdashboard/superdashboard.component';
import { DataTablesModule } from 'angular-datatables';



registerLocaleData(zh);

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    imports: [
        BrowserModule,
        DataTablesModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
            enableTracing: true,
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: 'reload'
        }),
        FormsModule,
        ChartModule,
        HttpClientModule,
        FullCalendarModule,
        BsDatepickerModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgSelectizeModule,
        NgbModule.forRoot(),
        NzModalModule,
        NgZorroAntdModule,
        HighchartsChartModule,
        CountUpModule
    ],
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        AuthenticationLayoutComponent,
        Page403Component,
        Page500Component,   
        PromotionSalesComponent, ReportComponent, SuperdashboardComponent,  
    ],
    providers: [CookieService, LocalStorage],
    bootstrap: [AppComponent]
})


export class AppModule { }
