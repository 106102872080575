import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { serializeData, headers } from '../../postformdata';

@Component({
	selector: 'app-promotion-sales',
	templateUrl: './promotion-sales.component.html',
	styleUrls: ['./promotion-sales.component.css']
	})
export class PromotionSalesComponent implements OnInit {

	constructor(private http:HttpClient) { }

	host = localStorage.getItem("host");

	ngOnInit() {
		this.draw()
				console.log(window.innerWidth)
	}


	ngOnDestory(){
		clearInterval(this.interval);
	}

	draw() {
		var canvas = <HTMLCanvasElement>document.getElementById('canvas');
		var main = <HTMLCanvasElement>document.getElementById('main');
		var ctx = canvas.getContext('2d');
		var w = canvas.width = main.width = window.innerWidth;
		var h = canvas.height = window.innerHeight;

		var hue = 217;
		var stars = [];
		var count = 0;
		var maxStars = 1300;//星星数量

		var canvas2 = document.createElement('canvas');
		var ctx2 = canvas2.getContext('2d');
		canvas2.width = 100;
		canvas2.height = 100;
		var half = canvas2.width / 2;
		var gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
		gradient2.addColorStop(0.025, '#CCC');
		gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)');
		gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)');
		gradient2.addColorStop(1, 'transparent');

		ctx2.fillStyle = gradient2;
		ctx2.beginPath();
		ctx2.arc(half, half, half, 0, Math.PI * 2);
		ctx2.fill();

		// End cache

		function random(min, max) {
			if (arguments.length < 2) {
				max = min;
				min = 0;
			}

			if (min > max) {
				var hold = max;
				max = min;
				min = hold;
			}

			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		function maxOrbit(x, y) {
			var max = Math.max(x, y),
			diameter = Math.round(Math.sqrt(max * max + max * max));
			return diameter / 2;
		  //星星移动范围，值越大范围越小，
		}

		var Star = function() {

			this.orbitRadius = random(0,maxOrbit(w, h));
			this.radius = random(60, this.orbitRadius) / 8; 
			//星星大小
			this.orbitX = w / 2;
			this.orbitY = h / 2;
			this.timePassed = random(0, maxStars);
			this.speed = random(0,this.orbitRadius) / 50000; 
			//星星移动速度
			this.alpha = random(0, 4) / 10;

			count++;
			stars[count] = this;
		}

		Star.prototype.draw = function() {
			var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,
			y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
			twinkle = random(0,10);

			if (twinkle === 1 && this.alpha > 0) {
				this.alpha -= 0.05;
				

			} else if (twinkle === 2 && this.alpha < 2) {
				this.alpha += 0.05;
			}
	
			ctx.globalAlpha = this.alpha;
			
			ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius);
			this.timePassed += this.speed;

			
		}

		for (var i = 0; i < maxStars; i++) {
			new Star();
		}

		function animation() {
			ctx.globalCompositeOperation = 'source-over';
			ctx.globalAlpha = 0.5; //尾巴
			ctx.fillStyle = 'hsla(' + hue + ', 64%, 6%, 2)';
			ctx.fillRect(0, 0, w, h)

			ctx.globalCompositeOperation = 'lighter';
			for (var i = 1, l = stars.length; i < l; i++) {
				stars[i].draw();
				
			};

			window.requestAnimationFrame(animation);
		}

		animation();

	
				
	}

		


	//大促数据定时刷新
	dateRange = []
	interval
	intervalValue = 1
	changeInterval(){
		clearInterval(this.interval);
		this.createInterval()
	}

	ChangeTime(event){
		clearInterval(this.interval);
		this.createInterval()
	}
	
	createInterval(){
		let that=this
		if(this.dateRange.length!=0){
			that.interval = setInterval(function(){
			     that.getPromotionData();
			},that.intervalValue*1000);

		}
	}

	company_total = 0;category_total = [];target_amount = [];isshow
	getPromotionData(){
		var data = {'st':this.dateRange[0],'ed':this.dateRange[1]}
		this.http.post(this.host+'/master/getpromotionsales/',serializeData(data),headers).subscribe(res=>{
			this.company_total = res['total']
			this.category_total = res['category']

		})
		this.http.post(this.host+'/master/gettargetdata/',serializeData(data),headers).subscribe(res=>{
			this.target_amount = res['result_data']
			this.isshow = res['isshow']
		})


	}


	


}
