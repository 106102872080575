import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpParams } from "@angular/common/http";
import { BsLocaleService, BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { serializeData, headers } from '../postformdata';
import swal from 'sweetalert2';
import { en_US, zh_CN, NzI18nService } from 'ng-zorro-antd';

@Component({
    selector: 'app-calendar',
    templateUrl: './common-layout.component.html'
})

export class CommonLayoutComponent implements OnInit {
    bsConfig2: Partial<BsDatepickerConfig>;
    minMode: BsDatepickerViewMode = 'month';
    public app: any;
    public headerThemes: any;
    public changeHeader: any;
    public sidenavThemes: any;
    public changeSidenav: any;
    public headerSelected: any;
    public sidenavSelected: any;
    public searchActived: any;
    public searchModel: any;
    userid = localStorage.getItem('userid');
    sourceStatus;
    reportStatus;
    calendarStatus;
    personalStatus;
    teamStatus;
    userStatus;
    dashboardStatus;
    presaleDashboardStatus;
    projectManagementStatus;
    hrManagementStatus;
    targetDashboardStatus;
    itStatus;
    managementStatus;
    locationUrl;
    host = localStorage.getItem("host");
    projects = localStorage.getItem("projects");
    teamView = false;
    userPermissionView = false;
    masterDashboardView = false;
    presaleDashboardView = false;
    superdashboardView = false;
    sourceView = false;
    reportView = false;
    projectManagementView = false;
    hrManagementView = false;
    targetDashboardView = false;
    itView = false;
    managementView = false;
    socialView=  false;
    socialStatus;
    superdashboardStatus;
    //设置多语言
    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private router: Router,
        private cookie: CookieService,
        private route: ActivatedRoute,
        private location: PlatformLocation,
        private antLang: NzI18nService
    ) {
        translate.addLangs(["zh", "en"]);
        translate.setDefaultLang('zh');
        antLang.setLocale(zh_CN);
        for (const i in this.location) {
            if (i === 'location') {
                this.locationUrl = this.location[i].hash;
                break;
            }
        }


        if (this.locationUrl == "#/view/personal-view") {
            this.personalStatus = "active"
        } else if (this.locationUrl == "#/view/team-view") {
            this.teamStatus = "active"
        } else if (this.locationUrl == "#/user/permission") {
            this.userStatus = "active"
        } else if (this.locationUrl == "#/master/dashboard") {
            this.dashboardStatus = "active"
        }else if (this.locationUrl == "#/master/presale-dashboard") {
            this.presaleDashboardStatus = "active"
        }else if (this.locationUrl == "#/master/project") {
            this.projectManagementStatus = "active"
        }else if (this.locationUrl == "#/master/hr") {
            this.hrManagementStatus = "active"
        }else if (this.locationUrl == "#/master/it") {
            this.itStatus = "active"
        }else if (this.locationUrl == "#/view/target-view") {
            this.targetDashboardStatus = "active"
        }else if (this.locationUrl == "#/master/management") {
            this.managementStatus = "active"
        }else if (this.locationUrl == "#/view/livestream") {
            this.socialStatus = "active"
        }else if (this.locationUrl == "#/sourcetraffic/source") {
            this.sourceStatus = "active"
        }else if (this.locationUrl == "#/report") {
            this.reportStatus = "active"
        }else if (this.locationUrl == "#/superdashboard") {
            this.superdashboardStatus = "active"
        }
        
        else {
            this.calendarStatus = "active"
        }
        
        this.app = {
            layout: {
                themeConfigOpen: false,
                rtlActived: false,
                searchActived: false
            }
        };

        //set themes
        this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
        this.changeHeader = changeHeader;

        function changeHeader(headerTheme) {
            this.headerSelected = headerTheme;
        }

        this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
        this.changeSidenav = changeSidenav;

        function changeSidenav(sidenavTheme) {
            this.sidenavSelected = sidenavTheme;
        }
    }

    browserLang = this.translate.getBrowserLang();;
    changeLanguage(language): void {
        if (language == "zh") {
            this.translate.use("zh");
            this.antLang.setLocale(zh_CN);
        } else if (language == "en") {
            console.log("change default language to en")
            this.translate.use("en");
            this.antLang.setLocale(en_US);
        }
    }

    getCommonPermission = function () {
        this.http.get(this.host + "/user/commonpermission/", headers).subscribe(data => {
            this.teamView = data['teamView']['enabled'];
            this.userPermissionView = data['userPermissionView']['enabled'];
            this.masterDashboardView = data['masterDashboardView']['enabled'];
            this.presaleDashboardView = data['presaleDashboardView']['enabled'];
            this.superdashboardView = data['superdashboardView']['enabled'];
            this.sourceView = data['sourceView']['enabled'];
            this.reportView = data['reportView']['enabled'];
            this.projectManagementView = data['projectManagementView']['enabled'];
            this.hrManagementView = data['hrManagementView']['enabled'];
            this.itView = data['itView']['enabled'];
            this.managementView = data['managementView']['enabled'];
            this.socialView = data['socialView']['enabled'];
            // this.targetDashboardView = data['targetView']['enabled']
        })
    }

    getProjects = function () {
        this.http.get(this.host + "/user/projects/", headers).subscribe(data => {
            this.projects = JSON.stringify(data);
            localStorage.setItem('projects', this.projects);
        })
    }

    //登出
    logout = function () {
        this.http.get(this.host + "/user/logout/", headers).subscribe(data => {
            console.log(data['msg']);
            localStorage.clear()
            this.cookie.deleteAll()
            this.router.navigate(['/authentication/sign-in'])
        })

    };

    ngOnInit() {
        this.changeLanguage(this.browserLang.match(/zh|en/) ? this.browserLang : 'zh');
        this.getCommonPermission();
        if (this.projects === null) {
            this.getProjects();
        }
    }
}