import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { Page403Component } from './extras/403/403.component';
import { Page500Component } from './extras/500/500.component';
import { PromotionSalesComponent } from './master/promotion-sales/promotion-sales.component';
import { ReportComponent } from './report/report.component';
import { SuperdashboardComponent } from './superdashboard/superdashboard.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/authentication/sign-in',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
            {
                path: 'apps',
                loadChildren: './apps/apps.modules#AppsModule'
            },
            {
                path: 'extras',
                loadChildren: './extras/extras.modules#ExtrasModule'
            },
            {
                path: 'view',
                loadChildren: './view/view.module#ViewModule'
            },
            {
                path: 'master',
                loadChildren: './master/master.modules#MasterModule'
            },
            {
                path: 'user',
                loadChildren: './user/user.modules#UserModule'
            },
            {
                path: 'sourcetraffic',
                loadChildren: './sourcetraffic/sourcetraffic.module#SourceModule'
            },
            {
                path: 'report',
                component: ReportComponent,
            },
            {
                path: 'superdashboard',
                component: SuperdashboardComponent,
            },
            {
                path: '403',
                component: Page403Component,
            },
            {
                path: '500',
                component: Page500Component,
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: './extras/authentication.modules#AuthenticationModule'
            }
        ]
    },
    {
        path: 'promotion-sales',
        component: PromotionSalesComponent
    },
    {
        path: 'details',
        loadChildren: './details/details.module#DetailsModule'
    },
];

