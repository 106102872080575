import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { serializeData, headers, Params } from './../postformdata';
import { startOfMonth, endOfMonth, subDays } from 'date-fns';
import * as Highcharts from 'highcharts';
import { format } from 'date-fns';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from './../datatable';
import { Router } from '@angular/router';
let stock = require('highcharts/modules/stock')
stock(Highcharts)
let more = require('highcharts/highcharts-more')
more(Highcharts)
@Component({
  selector: 'app-superdashboard',
  templateUrl: './superdashboard.component.html',
  styleUrls: ['./superdashboard.component.css']
})
export class SuperdashboardComponent implements OnInit {
  ispricemonitor: boolean;
  tmallOrders: any;
  jd_orders: any;
  kaola_orders: any;
  tmall_sales: any;
  jd_sales: any;
  kaola_sales: any;
  total_refund: any;
  total_orders: any;
  tmall_orders: any;
  color = ["#ef9a9a", "#F48FB1", "#CE93D8", "#B39DDB", "#9FA8DA", "#90CAF9",
    "#81D4FA", "#80DEEA", "#80CBC4", "#A5D6A7", "#C5E1A5", "#E6EE9C",
    "#FFF59D", "#FFE082", "#FFCC80", "#FFAB91", "#BCAAA4", "#EEEEEE",
    "#B0BEC5", "#e57373", "#F06292", "#BA68C8", "#9575CD", "#7986CB",
    "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#81C784", "#AED581",
    "#DCE775", "#FFF176", "#FFD54F", "#FFB74D", "#FF8A65", "#A1887F",
    "#E0E0E0", "#90A4AE"]
  Keywordvalue: '';
  hansgrohe: any;
  priceisVisible: boolean;
  uuidvalue: any;
  storenamevalue: any;
  newminpricevalue: any;
  newmaxpricevalue: any;
  competitorsdata=[];
  iscompetitors: boolean;
  updata: any;
  drilldown: any;
  loadingLiveSales: boolean;
  loadingSales: boolean;
  loadingorders: boolean;
  loadingBuyers: boolean;
  loadingComments: boolean;
  loadingPaidmarketing: boolean;
  checkVisible: boolean;
  Uploaded: any;
  noUpload: any;
  email_list: any;
  checkisLoading: boolean;
  skeloading: boolean;
  loadingBuyerlist: boolean;
  loadingzhitonche: boolean;
  constructor(private http: HttpClient, private router: Router) { }
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}
  bundleOptions;
  daterangepickerModel;
  isVisible = false;
  host = localStorage.getItem('host')
  data; testtype;
  store;
  store_names = [];
  store_name;
  allChecked = false;
  indeterminate = true;
  dict_data;
  paidmarketingdate;
  colors = ['red', 'blue', 'yellow', 'green'];
  commentsposdata = []; commentsngdata = [];
  pageIndex = 1;
  pageSize = 10;
  total = 1;
  pricestore = 'hansgrohe';
  keyword = '';
  maxpricevalue = 0;
  minpricevalue = 0;
  dateRange = [subDays(new Date(), 6), new Date()]; 
  orderdateRange = [subDays(new Date(), 8), new Date()]; 
  saledateRange = [subDays(new Date(), 8), new Date()]; 
  paidmarketdateRange = subDays(new Date(), 2)
  checkOptionsOne = [];
  competitors_keyword = '';competitors_store = 'hansgrohe';
  conSpinning;
  competitorslist = []
  loadingitem;
  ngOnInit(): void {
    this.commonstore()
  }
  commonstore() {
    var data = {
    }
    new Promise((resolve)=>
    this.http.post(this.host + '/superdashboard/commonstore/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.checkOptionsOne = res['data']
        resolve()
      }
    }
    )).then(res=>{
      this.changepage('Sales')
    })

  }
  onChange(result: Date[]): void {
    this.dateRange = result
    this.changepage(this.testtype)

  }
  changepage(type) {
    this.testtype = type
    if (type == 'Sales') {
      this.sales('weekly')
      this.sales('monthly')
      this.sales('yearly')
      this.getlivesales()
    }
    else if (type == 'Oders') {
      this.order()
    }
    else if (type == 'item') {
      this.item()
    }
    else if (type == 'Buyers') {
      this.buyerdata()
    }
    else if (type == 'Comments') {
      this.comments()
    }
    else if (type == 'CustomerService') {
      this.customerservice()
    }
    else if (type == 'Paidmarketing') {
      this.paidMarket()
    }
    else if (type == 'Competitors') {
      this.competitors()
    }
    else if (type == 'pricemonitor') {
      this.pricemonitor()
    }
    else if (type == 'brandmonitor') {
      this.brandmonitor()
    }


  }
  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: true
        };
      });
    } else {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: false
        };
      });
    }
  }

  updateSingleChecked(): void {
    if (this.checkOptionsOne.every(item => item.checked === false)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.checkOptionsOne.every(item => item.checked === true)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }
  showcheckModal(){
    this.checkVisible = true;
    this.checkdata()
  }
  checkhandleOk(): void {
    this.checkVisible = false;
  }

  checkhandleCancel(): void {
    this.checkVisible = false;
  }
  
  showStoresModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    this.changepage(this.testtype)
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  //common
  checkdata(){
    var data = {

    }

    this.http.post(this.host + '/superdashboard/superdashboard/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.Uploaded = res['data']['uploaded']
        this.noUpload = res['data']['no_upload']

      }
    }
    )
  }

  checkdatavalue(value){
    this.email_list = value
  }
  senderEmail(){
    this.checkisLoading = true
    var data = {
     "email_list":this.email_list
    }

    this.http.post(this.host + '/superdashboard/SendReminder/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        setTimeout(() => {
          this.checkisLoading = false;
        }, 3000);

      }
    }
    )
  }
  //sales
  total_sales; totalOrders;
  sales(type) {
    this.loadingSales = true
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
      'type': type,
      "data_type": "sales"
    }

    this.http.post(this.host + '/superdashboard/todaysales/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.loadingSales = false
        this.todaysales(result)

      }
    }
    )
    this.http.post(this.host + '/superdashboard/periodsales/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {

        var x = res['data']['x']
        var sales_result = res['data']['sales_result']
        if (type == 'weekly') {
          var title = "Week's Sales (Without Refunds)/本周销售额(不含退款)"
        } else if (type == 'monthly') {
          var title = "Month's Sales (Without Refunds)/本月销售额(不含退款)"
        } else if (type == 'yearly') {
          var title = "Year's Sales (Without Refunds)/本年销售额(不含退款)"
        }
        this.salescharts(type, title, x, sales_result)
      }
    }
    )
    this.searchsales()
  }
  getlivesales() {
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'stores': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }

    this.http.post(this.host + '/superdashboard/getlivesales/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.total_orders = res['data']['total_orders']
        this.tmall_orders = res['data']['tmall_orders']
        this.jd_orders = res['data']['jd_orders']
        this.kaola_orders = res['data']['kaola_orders']

        this.total_sales = res['data']['total_sales']
        this.tmall_sales = res['data']['tmall_sales']
        this.jd_sales = res['data']['jd_sales']
        this.kaola_sales = res['data']['kaola_sales']

        this.total_refund = res['data']['total_refund']
      }
    }
    )
  }
  searchsales() {
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'stores': stores,
      'st': format(this.saledateRange[0], "YYYY-MM-DD"),
      'ed': format(this.saledateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/selectsales/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.selectsalescharts(format(this.saledateRange[0], "YYYY-MM-DD"), format(this.saledateRange[1], "YYYY-MM-DD"), result)
      }
    }
    )
  }
  todaysales(result) {
    var chart = Highcharts.chart('todaysales', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      title: {
        text: "Today Sales Trend/今日销售走势"
      },
      xAxis: {
        categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
      },
      yAxis: {
        title: {
          text: null
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom'
      },
      series: result,
    });

  }
  salescharts(type, title, x, sales_result) {
    Highcharts.chart(type + 'sales', {
      credits: {
        enabled: false
      },
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: title
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      xAxis: [{
        categories: x,
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: 'Daily Sales',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Weekly Accumulated Sales',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        // backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },
      series: sales_result
    });

  }
  selectsalescharts(st, ed, result) {
    Highcharts.chart('select-sales', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: st + '~' + ed + ' Sales'
      },
      colors: this.color,
      xAxis: [{
        type: 'datetime',
        labels: {
          format: '{value:%Y-%m-%d}',
          rotation: 45,
          align: 'left'
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value} RMB',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: 'Daily Sales',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Weekly Accumulated Sales',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value} RMB',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:,.2f} RMB</b><br/>',
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        // backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },
      series: result
    });
  }
  //order
  order() {
    this.store_names = []
    this.loadingorders = true
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        if (this.store_names.indexOf(store['value']) == -1) {
          this.store_names.push(store['value'])
        }
      }
    }
    for (let i = 0; i < this.store_names.length; i++) {
      this.store_name = this.store_names[i]
      this.orderorign(this.store_name)
    }
    var data = {
      'store': this.store_names,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/todayorders/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.loadingorders = false
        this.todayordercharts(result)

      }
    }
    )
    this.http.post(this.host + '/superdashboard/ordertime/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.ordertimecharts(result)

      }
    }
    )
    this.orderperiod('weekly')
    this.orderperiod('monthly')
    this.orderperiod('yearly')
    this.searchorders()
  }
  orderorign(name) {
    var data = {
      'store': name,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/orderregion/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        var state_dic = new Array();
        var count = new Array();
        for (var i = 0; i < result.length; i++) {
          state_dic.push(result[i].state);
          count.push(result[i].count);
        }
        var data = { "name": "count", "data": count }
        this.orderorigncharts(name, state_dic, data)
      }
    }
    )
  }
  orderperiod(type) {
    var data = {
      'store': this.store_names,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
      'type': type,
      "data_type": "orders"
    }
    this.http.post(this.host + '/superdashboard/periodsales/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var x = res['data']['x']
        var sales_result = res['data']['sales_result']
        if (type == 'weekly') {
          var title = "Week's Orders/本周订单数"
        } else if (type == 'monthly') {
          var title = "Month's Orders/本月订单数"
        } else if (type == 'yearly') {
          var title = "Year's Orders/本年订单数"
        }
        this.orderperiodcharts(type, title, x, sales_result)
      }
    }
    )
  }
  searchorders() {
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'stores': stores,
      'st': format(this.orderdateRange[0], "YYYY-MM-DD"),
      'ed': format(this.orderdateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/selectorders/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.selectordercharts(format(this.orderdateRange[0], "YYYY-MM-DD"), format(this.orderdateRange[1], "YYYY-MM-DD"), result)
      }
    }
    )
  }
  todayordercharts(result) {
    Highcharts.chart('todayorders', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      title: {
        text: "Today Orders Trend/今日订单数"
      },
      xAxis: {
        categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom'
      },
      series: result,
    });
  }
  ordertimecharts(result) {
    Highcharts.chart('ordertime', {
      credits: { enabled: false },
      chart: {
        type: 'column'
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      title: {
        text: 'Order Time/下单时间'
      },
      xAxis: {
        categories: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        }
      },
      tooltip: {
        // head + 每个 point + footer 拼接成完整的 table
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.,f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          borderWidth: 0
        }
      },
      series: result,
    });
  }
  orderorigncharts(store, state_dic, data) {
    Highcharts.chart(store + '-orders-district', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        type: 'column'
      },
      title: {
        text: store + ' Orders District/订单来源地'
      },
      xAxis: {
        categories: state_dic,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            allowOverlap: true // 允许数据标签重叠
          }
        }
      },
      legend: {
        enabled: false,
      },
      series: [data]
    });
  }
  orderperiodcharts(type, title, x, sales_result) {
    Highcharts.chart(type + 'orders', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: title
      },
      xAxis: [{
        categories: x,
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: 'Daily Sales',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Weekly Accumulated Sales',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        // backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },
      series: sales_result
    });

  }
  selectordercharts(st, ed, result) {
    Highcharts.chart('select-orders', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: st + '~' + ed + ' Orders & Average Basket Value'
      },
      colors: this.color,
      xAxis: [{
        type: 'datetime',
        labels: {
          format: '{value:%Y-%m-%d}',
          rotation: 45,
          align: 'left'
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: 'Daily Orders',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Average Basket Value',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value} RMB',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:,.2f}</b><br/>',
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        // backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },
      series: result
    });
  }

  //item
  itemlistdata = []
  item() {
    this.store_names = []
    this.loadingitem = true
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        if (this.store_names.indexOf(store['value']) == -1) {
          this.store_names.push(store['value'])
        }
      }
    }
    for (let i = 0; i < this.store_names.length; i++) {
      this.store_name = this.store_names[i]
      this.itemdata(this.store_name)
    }
    var data = {
      'store': this.store_names,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/itemlist/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.itemlistdata = res['data']
      }
    }
    )

  }

  itemdata(stores) {
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/itemsales/', serializeData(data), headers).subscribe(res => {
      var result = []; var chartsname;
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        result = res['data']
        this.loadingitem = false
        this.itemsalescharts(stores, 'sales', result)
      }
    }
    )
    this.http.post(this.host + '/superdashboard/itemtraffic/', serializeData(data), headers).subscribe(res => {
      var result = []; var chartsname;
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        result = res['data']
        this.itemsalescharts(stores, 'traffic', result)
      }
    }
    )
  }
  itemsalescharts(store_name, type, result) {
    Highcharts.chart(store_name + '-item-' + type, {
      credits: { enabled: false },
      chart: {
        spacing: [40, 0, 40, 0]
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      title: {
        floating: true,
        text: 'Sales Contribution'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          center: ["50%", "50%"],
          ignoreHiddenPoint: false,
          dataLabels: {
            // crop: false,
            enabled: true,
            padding: 0,
            connectorPadding: 0,
            format: '<b>{point.name}</b>: {point.y:,.1f} RMB/{point.percentage:.1f} %',

          },
        }
      },
      series: [{
        type: 'pie',
        innerSize: '80%',
        name: 'Contribution/贡献',
        data: result
      }]
    });
  }
  buyerlistdata = []
  //buyers
  buyerdata() {
    this.loadingBuyerlist = true
    this.loadingBuyers = true
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/buyerhistory/', serializeData(data), headers).subscribe(res => {
      var result = []; var chartsname;
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        result = res['data']
        chartsname = res['chartsname']
        this.loadingBuyers = false
        this.buyercharts(chartsname,"Old Buyer&New Buyer/新旧买家数",result)
      }
    }
    )
    this.http.post(this.host + '/superdashboard/buyergender/', serializeData(data), headers).subscribe(res => {
      var result = []; var chartsname;
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        result = res['data']
        chartsname = res['chartsname']
        this.buyercharts(chartsname,"Buyer Gender/买家性别",result)
      }
    }
    )
    this.http.post(this.host + '/superdashboard/buyerlist/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.loadingBuyerlist = false
        this.buyerlistdata = res['data']
        
      }
    }
    )

  }

  buyercharts(chartsname,titlename,result) {
    Highcharts.chart(chartsname, {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        type: 'column'
      },
      title: {
        text: titlename,
      },
      subtitle: {
        text: null
      },
      yAxis: [{
        title: {
          text: null
        }
      },],
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%Y-%m-%d}',
          rotation: 45,
          align: 'left'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        floating: true,
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 1
        }
      },
      series: result,
    });
  }

  //comments
  comments() {
    this.store_names = []
    this.loadingComments = true
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        if (this.store_names.indexOf(store['value']) == -1) {
          this.store_names.push(store['value'])
        }
      }
    }
    for (let i = 0; i < this.store_names.length; i++) {
      this.store_name = this.store_names[i]
      this.commentsdata(this.store_names)
      this.commentsreply(this.store_name, 'positive')
      this.commentsreply(this.store_name, 'negative')
      this.commentsreply(this.store_name, 'total')
    }
  }
  commentcount
  commentsdata(stores) {
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
      'enforce_date': 'True'
    }

    this.http.post(this.host + '/superdashboard/commentscount/', serializeData(data), headers).subscribe(res => {

      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.commentcount = res['data']
      }
    }
    )
    this.http.post(this.host + '/superdashboard/commentsresponse/', serializeData(data), headers).subscribe(res => {

      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.commentsreponsecharts(result)
      }
    }
    )

    this.http.post(this.host + '/superdashboard/commentslist/', serializeData(data), headers).subscribe(res => {

      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.commentsposdata = res['data']['positive']
        this.commentsngdata = res['data']['negative']
      }
    }
    )
  }

  commentsreply(store, type) {
    var data = {
      'store': store,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
      'enforce_date': 'True',
      'type': type
    }
    this.http.post(this.host + '/superdashboard/commentsreply/', serializeData(data), headers).subscribe(res => {
      var result = []; var chartsname;
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        result = res['data']
        var newdata = {
          name: 'If Reply',
          colorByPoint: true,
          data: result
        }
        this.loadingComments = false
        this.commentscharts(store, type, newdata)
      }
    }
    )
  }
  commentscharts(store_name, type, data) {
    Highcharts.chart(store_name + '-' + type + '-reply', {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          center: ["50%", "50%"],
          ignoreHiddenPoint: false,
          dataLabels: {
            // crop: false,
            enabled: true,
            padding: 0,
            connectorPadding: 0,
            format: '<b>{point.name}</b>: {point.y:,.f}/{point.percentage:.1f} %',
          },
        }
      },
      series: [data],
    })
  }
  commentsreponsecharts(result) {
    Highcharts.chart('comments-response', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      xAxis: {
        categories: [
          'Total Comments', 'Positive Comments', 'Negative Comments'
        ],
        crosshair: true
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        // head + 每个 point + footer 拼接成完整的 table
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} h</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          borderWidth: 0
        }
      },
      series: result
    });
  }

  //Customer Service
  Customerdata = [];
  customerservice() {
    this.store_names = []
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        if (this.store_names.indexOf(store['value']) == -1) {
          this.store_names.push(store['value'])
        }
      }
    }
    this.customerlist(this.store_names)
  }
  customerlist(store) {
    var data = {
      'store': store,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/customerservice/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.Customerdata = res['data']
      }
    }
    )
  }

  //paid Marketing
  listOfDisplayData = [];
  newonChange(result: Date): void {
    this.paidmarketingdate = result
  }
  pksearch(): void {
    // this.changepage(this.testtype)
    this.searchpaidmarketing(this.store_names)
  }
  sortName; sortValue; listOfSearchName: string[] = [];
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }
  search(): void {
    /** filter data **/
    const filterFunc = (item: { title: string; }) =>
      (this.listOfSearchName.length ? this.listOfSearchName.some((title) => item.title.indexOf(title) !== -1) : true);
    const data = this.listOfDisplayData.filter((item) => filterFunc(item));
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
    } else {
      this.listOfDisplayData = data;
    }
  }

  paidMarket() {
    this.loadingzhitonche = true
    this.store_names = []
    this.loadingPaidmarketing = true
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        if (this.store_names.indexOf(store['value']) == -1) {
          this.store_names.push(store['value'])
        }
      }
    }
    for (let i = 0; i < this.store_names.length; i++) {
      this.store_name = this.store_names[i]
      this.paidMarketing(this.store_name);
      this.sourcedata(this.store_name, 'pc');
      this.sourcedata(this.store_name, 'mobile');
    }
    this.searchpaidmarketing(this.store_names)
  }
  paidMarketing(stores) {
    var data2 = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/paidmarketingpxb/', serializeData(data2), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.loadingPaidmarketing = false
        this.paidcharts(stores, 'pxb', result)
      }
    }
    )
    this.http.post(this.host + '/superdashboard/paidmarketingtbk/', serializeData(data2), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.paidcharts(stores, 'taobaok', result)
      }
    }
    )
    this.http.post(this.host + '/superdashboard/paidmarketingzz/', serializeData(data2), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.paidcharts(stores, 'zuanzhan', result)
      }
    }
    )
  }
  sourcedata(store, platform) {
    var data = {
      'store': store,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
      'platform': platform
    }
    this.http.post(this.host + '/master/sourcetraffic/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.updata = res['updata']
        this.drilldown = res['drilldown']
        this.loadingLiveSales = false
        this.sourcecharts(store, platform, this.updata, this.drilldown)
      }
    }
    )

  }
  sourcecharts(store, platform, updata, drilldowndata) {
    // var result = eval("(" + result + ")");
    Highcharts.chart(store + '-' + platform, {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: null
        }
      },
      tooltip: {
        // head + 每个 point + footer 拼接成完整的 table
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.2f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      plotOptions: {
        column: {
          borderWidth: 0
        }
      },
      series: updata,
      drilldown: {
        series: drilldowndata
      }
    });
  }
  searchpaidmarketing(stores) {
    var data = {
      'store': stores,
      'date': format(this.paidmarketdateRange, "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/paidmarketingztc/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.loadingzhitonche = false
        this.listOfDisplayData = res['data']
      }
    }
    )
  }
  paidcharts(store, type, result) {
    Highcharts.chart(store + type, {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      chart: {
        type: 'line'
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      yAxis: [{
        title: {
          text: null
        }
      },],
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%Y-%m-%d}',
          rotation: 45,
          align: 'left'
        }
      },
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 2010
        }
      },
      series: result,
    });
  }

  //Competitors
  showcompetitorsModal(){
    this.iscompetitors = true;
  }
  cphandleOk(): void {
    this.iscompetitors = false;
    this.addcompetitors()
  }

  cphandleCancel(): void {
    this.iscompetitors = false;
  }
  competitorlenth = [1,2,3,4,5,6,7,8,9,10]
  competitors() {
    this.skeloading = true

    this.conSpinning = true
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/competitorlist/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.competitorsdata = res['data']
      }
    }
    )
    this.conSpinning = false
    this.competitorschartdata('sales');
    this.competitorschartdata('quantity');
    this.competitorschartdata('unitprice');

    this.http.post(this.host + '/superdashboard/competitorhotlist/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.skeloading = false
        this.competitorslist = res['data']
      }
    }
    )
  }
  competitorschartdata(type) {
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'store': stores,
      'type': type
    }
    this.http.post(this.host + '/superdashboard/competitorsales/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        var result = res['data']
        this.competitorscharts(type, result);

      }
    }


    )
  }

  addcompetitors(){
    var data = {
      'store_name': this.competitors_store,
      'keyword': this.competitors_keyword,
    }
    this.http.post(this.host + '/superdashboard/addcompetitor/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
            this.competitors()
      }
    }
    )
  }
  operatecompetitors(type,store_name,competitor_shopid) {
    var data = {
      "type": type,
      "store_name":store_name,
      "competitor_shopid": competitor_shopid
    }
    this.http.post(this.host + '/superdashboard/competitoroperation/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.competitors()
      }
    }
    )
  }
  competitorscharts(name, result) {
    // Highcharts.each(result, function (d) {
    //   d.length = 2;
    // });
    Highcharts.stockChart('competitor' + name, {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
      },
      rangeSelector: {
        selected: 0
      },
      title: {
        text: null
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          showInLegend: true
        },
        line: {
          marker: {
            enabled: true
          }
        }
      },
      tooltip: {
        split: false,
        shared: true,
      },
      series: result
    });
  }
  //Price monitor
  showpriceStoresModal() {
    this.ispricemonitor = true
  }
  newhandleOk() {
    this.ispricemonitor = false
    this.addpricemonitor()
  }
  newhandleCancel() {
    this.ispricemonitor = false
  }
  pricehandleOk(): void {
    this.priceisVisible = false;
  }

  pricehandleCancel(): void {
    this.priceisVisible = false;
  }

  editpriceStoresModal(uuidvalue) {
    this.priceisVisible = true
    this.getkeyworddetail(uuidvalue)
  }

  pricemonitordata = []
  pricemonitor() {
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/pricemonitorlist/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.pricemonitordata = res['data']
      }
    }
    )
  }
  addpricemonitor() {
    var data = {
      'store_name': this.pricestore,
      'keyword': this.keyword,
      'min_price': this.minpricevalue,
      'max_price': this.maxpricevalue,
    }
    this.http.post(this.host + '/superdashboard/addpricemonitor/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.pricemonitor()
      }
    }
    )
  }
  getkeyworddetail(uuidvalue) {
    var data = {
      'uuidvalue': uuidvalue,

    }
    this.http.post(this.host + '/superdashboard/getpmkeyworddetail/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.uuidvalue = res[0]['pk']
        this.newminpricevalue = res[0]['fields']['min_price']
        this.storenamevalue = res[0]['fields']['store_name']
        this.newmaxpricevalue = res[0]['fields']['max_price']
        this.Keywordvalue = res[0]['fields']['keyword']
      }
    }
    )
  }
  operatepricemonitor(type, uuid) {
    var data = {
      "type": type,
      "uuid": uuid
    }
    this.http.post(this.host + '/superdashboard/pricemonitoroperation/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.pricemonitor()
      }
    }
    )
  }
  //brandmonitor
  brandmonitordata = []
  brandmonitor() {
    var stores = [];
    for (let store of this.checkOptionsOne) {
      if (store['checked'] == true) {
        stores.push(store['value'])
      }
    }
    var data = {
      'store': stores,
      'st': format(this.dateRange[0], "YYYY-MM-DD"),
      'ed': format(this.dateRange[1], "YYYY-MM-DD"),
    }
    this.http.post(this.host + '/superdashboard/getstorebrand/', serializeData(data), headers).subscribe(res => {
      if (res['msg'] == "Permission Denied!") {
        document.getElementById('container').innerHTML = '<p style="text-align: center;padding-top: 15%;font-size:24px">Permission Denied!</p>'
      } else {
        this.brandmonitordata = res['data']
      }
    }
    )
  }
}
